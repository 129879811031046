.App *{
	outline: none;
}

.App {
  background-color: #f0f1f4;
  text-align: center;
  box-shadow: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-icon {
  height: 30vmin;
  pointer-events: none;
}

.App-down {
  height: 5vmin;
  pointer-events: none;
}

.App-header {
  background-color: #f0f1f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-title {
    text-align:left;
    margin-left:15vmin;
    font-size: calc(5px + 5vmin);
}

.App-body {
    text-align:left;
    margin-left:5vmin;
    font-size: calc(10px + 1vmin);
}

.App-header-t {
    font-size: calc(5px + 5vmin);
}

.App-header-p {
    font-size: calc(8px + 2vmin);
}
.App-link {
	color: #61dafb;
}

.App-testcard{
    margin-left:15vmin;
    margin-right:15vmin;

}

.bm * {
	outline:none;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  box-shadow: none;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}


.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
